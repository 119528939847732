import {InsertTextLine} from "./InsertTextLine";

export function ProtectionDevices({ raw_info, system_type, updateParameter }) {
    if (raw_info["ver_type"] !== "Functioning" || system_type !== "gvr") {
        console.log("ProtectionDevice - ",raw_info," - type:", raw_info["verify_type"], "system_type:", system_type)
        return null;
    }
    const values = [
        {
            label: "Accessori di sicurezza",
            placeholder: "Inserisci i dettagli sugli accessori di sicurezza",
            key: "acc_sec"
        },
        {
            label: "Dispositivi di controllo",
            placeholder: "Inserisci i dettagli sui dispositivi di controllo",
            key: "device_control"
        },
        {
            label: "Dispositivi di regolazione",
            placeholder: "Inserisci i dettagli sui dispositivi di regolazione",
            key: "device_reg"
        },
        {
            label: "Altri accessori rilevanti",
            placeholder: "Inserisci i dettagli sugli altri accessori rilevanti",
            key: "acc_ril"
        },
        {
            label: "Alimentazione",
            placeholder: "Inserisci i dettagli sull'alimentazione",
            key: "alimentation"
        },
        {
            label: "Valvole di intercettazione",
            placeholder: "Inserisci i dettagli sulle valvole di intercettazione se presenti",
            key: "valve"
        }
    ]
    return (
        <>
            <h3>Dispositivi di protezione</h3>
            <div className="protection-devices">
                <div className="protection-devices">
                    {values.map((item, index) => (
                        <InsertTextLine
                            key={index}
                            label={item.label}
                            placeholder={item.placeholder}
                            all_info={raw_info}
                            all_info_key={item.key}
                            onChange={updateParameter}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}
