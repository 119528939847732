import './CSS/TechnicalDataTable.css';
import {InsertTextLine} from "./Form/InsertTextLine";
import {ConductorInfo} from "./Form/ConductorInfo";

const headers = ["Attr./Camere", "N.F.", "PS (bar)", "P eserc.(bar)", "TS(°C)", "T eserc.(°C)", "Cat.", "Natura", "Stato", "Gruppo", "Capacità (l)"];

export function TechnicalDataTable({ raw_info, system_type, updateParameter }) {

    if(system_type !== "gvr") {
        return null;
    }

    let table_info = {
        headers: headers,
        rows: []
    };

    // Populate the table with the raw data
    for (let i = 1; i <= 3; i++) {
        table_info.rows.push([
            raw_info[`attrezzature_${i}`],
            raw_info[`n_f_${i}`],
            raw_info[`ps_${i}`],
            raw_info[`p_eserc_${i}`],
            raw_info[`ts_${i}`],
            raw_info[`t_eserc_${i}`],
            raw_info[`cat_${i}`],
            raw_info[`natura_${i}`],
            raw_info[`stato_${i}`],
            raw_info[`gruppo_${i}`],
            raw_info[`capacita_${i}`]
        ]);
    }
    // Add supeficie, producibilità, potenza, capacità totale (lt)
    const lastRow = [
        raw_info.superficie,
        raw_info.producibilita,
        raw_info.kW,
        raw_info.capacita_totale
    ];

    console.log("Table info: ", table_info);
    return (
        <>
            <h3>Configurazione e dati tecnici</h3>
            <div className="technical-data-table">
                <table>
                    <thead>
                    <tr>
                        {table_info.headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {table_info.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="technical-data-row">
                    <div className="tdt_label superficie">Superficie (m)</div>
                    <div className="tdt_value superficie">{lastRow[0]}</div>
                    <div className="tdt_label producibilita">Prod. (t/h)</div>
                    <div className="tdt_value producibilita">{lastRow[1]}</div>
                    <div className="tdt_label potenza">Potenza (kW)</div>
                    <div className="tdt_value potenza">{lastRow[2]}</div>
                    <div className="tdt_label capacita_t">Capacità tot (l)</div>
                    <div className="tdt_value capacita_t">{lastRow[3]}</div>
                </div>
            </div>
            <InsertTextLine
                label={"Breve descrizione"}
                placeholder={"Inserisci una breve descrizione"}
                all_info={raw_info}
                all_info_key={"description"}
                onChange={updateParameter}
            />
            <ConductorInfo raw_info={raw_info} updateParameter={updateParameter}/>
        </>

    );
}