import './CSS/Line.css';
import {useEffect, useState} from "react";
import {DetailsTab} from "./DetailsTab";
import {GIcon} from "./GIcon";
import {GenericPopup} from "./GenericPopup";
import {silviodb_url} from "./index";
import {Note} from "./Note";

export function Line({count, info, setDownloadMessage, setNewChange}) {
    const min_width = 1000;
    // The tab containing the system details and reports
    const [tabShown, toggleTab] = useState(false)
    //The confirmation popup status
    const [confirmationStatus, updateConfirmationStatus] = useState({})
    const [isMobile, setIsMobile] = useState(window.innerWidth <= min_width);
    const [mobileInfo, setMobileInfo] = useState({"date_key": "label"});
    const [copied, setCopied] = useState(false);
    const [isEditingNote, setIsEditingNote] = useState(false);
    let dateTypes = [
        {'label': "Periodica:", "short":"Per.", 'value': info.next_periodic},
        {'label': "Funzionamento:", "short":"Funz.", 'value': info.next_functioning},
        {'label': "Integrità:", "short":"Integr.", 'value': info.next_integrity},
        {'label': "Interna:", "short":"Interna", 'value': info.next_internal}
    ];

    useEffect(() => {
        // Abbrevia il testo se necessario
        setMobileInfo((prev) => ({
            ...prev,
            date_key: isMobile ? "short" : "label",
        }));
    }, [isMobile]);

    useEffect(() => {
        // Aggiorna lo stato al resize della finestra
        const handleResize = () => {
            setIsMobile(window.innerWidth <= min_width);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const showDetails = (e) =>  {
        const prevent_show = ['sn', 'save_note']
        console.log(e.target)
        if (!prevent_show.some(cls => e.target.classList.contains(cls))) {
            toggleTab(!tabShown)
        }
    }

    const fermooo = (e) => {
        e.stopPropagation()
    }

    const handleKeyPress = (e, myFunction) => {
        if (e.key === 'Enter') {
            console.log("Hai premuto invio")
            myFunction(e)
        }
    }

    const dismissMessage = (e) => {
        updateConfirmationStatus({});
    }

    const setSnooze = (e) => {
        updateConfirmationStatus({'status': 'loading'});
        return fetch(silviodb_url+'/ignora?sn='+info['sn_new'])
            .then((response) => response.json())
            .then((r) => {
                setNewChange({'action': 'delete', 'item': info['sn_new']});
                updateConfirmationStatus({'status': 'success', 'deleted': info['sn_new']});
                console.log(r);
            })
            .catch((r) => {
                updateConfirmationStatus({'status': 'failed'});
                console.log(r);
            });
    }

    const setDelete = (e) => {
        updateConfirmationStatus({'status': 'loading'});
        return fetch(silviodb_url+'/elimina?sn='+info['sn_new'])
            .then((response) => response.json())
            .then((r) => {
                setNewChange({'action': 'delete', 'item': info['sn_new']});
                updateConfirmationStatus({'status': 'success', 'deleted': info['sn_new']});
                console.log(r);
            })
            .catch((r) => {
                updateConfirmationStatus({'status': 'failed'});
                console.log(r);
            });
    }

    const handleSnoozeButton = (e) => {
        let sn = e.currentTarget.dataset['sn'];
        let client = e.currentTarget.dataset['client'];
        e.stopPropagation();
        updateConfirmationStatus({
            'msg': 'Nascondere questa matricola?',
            'sub_msg': 'La matricola ' + sn + ' di ' + client + ' e le relative verifiche non appariranno più nella lista per i prossimi 30 giorni',
            'img': 'zzz.svg',
            'dismiss': dismissMessage,
            'buttons': [
                {'title': 'Annulla', 'action': dismissMessage},
                {'title': 'Conferma', 'action': setSnooze}
            ]
        });
    }

    const handleDeleteButton = (e) => {
        let sn = e.currentTarget.dataset['sn'];
        let client = e.currentTarget.dataset['client'];
        e.stopPropagation();
        updateConfirmationStatus({
            'msg': 'Cancellare questa matricola?',
            'sub_msg': 'La matricola ' + sn + ' di ' + client + ' e le relative verifiche verranno eliminate dalla lista.',
            'img': 'eraser.svg',
            'dismiss': dismissMessage,
            'buttons': [
                {'title': 'Annulla', 'action': dismissMessage},
                {'title': 'Elimina', 'action': setDelete}
            ]
        });
    }

    const handleAddNote = (e) => {
        e.stopPropagation();
        if (!isEditingNote) {
            console.log("Editing note")
            setIsEditingNote(true);
        } else {
            console.log("Saving note")
            setIsEditingNote(false);
        }
        setNewChange({'action': 'modify', 'item': info['sn_new'], 'field': 'equipment_note', 'value': info.equipment_note||' '});
    }

    const buttons = {
        delete: {title: 'Demolito', action: handleDeleteButton},
        edit_note: {title: 'Nota', action: handleAddNote},
        notifications_paused: {title: 'Nascondi', action: handleSnoozeButton}
    }

    const handle_sn_copy = (event) => {
        event.preventDefault();
        event.stopPropagation();
        navigator.clipboard.writeText(info.sn_new);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    return (<>
            <div className={'line_container'}
                 tabIndex={0}
                 onClick={(e) => showDetails(e)}
                 onKeyDown={(e) => handleKeyPress(e, showDetails)}
            >
                <span className={'line_num'}>{count}</span>
                <span className={`client_name`}>{info.client_name}</span>
                <span className={'city'}>{info.city} ({info.province})</span>
                <span
                    className={`sn ${copied ? "copied" : ""}`}
                    onClick={handle_sn_copy}
                >{copied ? "✅ Matricola copiata" : info.sn_new}</span>
                <span className={'type'}>[{info.system_type}] {info.system_info}</span>
                {detectExpiration(info, isMobile)}
                {detectDates(info, dateTypes, mobileInfo.date_key)}
                <div className={'buttons'}>
                    <button className={'my_button'}
                            title={buttons.edit_note.title}
                            onClick={handleAddNote}
                            onKeyDown={(e) => handleKeyPress(e, handleAddNote)}
                    >
                        <GIcon name={'edit_note'}/>
                        <span className={'button_text'}>{buttons.edit_note.title}</span>
                    </button>
                    <button className={'my_button'}
                            title={buttons.delete.title}
                            onClick={handleDeleteButton}
                            onKeyDown={(e) => handleKeyPress(e, fermooo)}
                            data-sn={info.sn_new}
                            data-client={info.client_name}
                    >
                        <GIcon name={'delete'}/>
                        <span className={'button_text'}>{buttons.delete.title}</span>
                    </button>
                    {/*<button className={'my_button'}*/}
                    {/*        title={buttons.notifications_paused.title}*/}
                    {/*        onClick={handleSnoozeButton}*/}
                    {/*        onKeyDown={(e) => handleKeyPress(e, handleSnoozeButton)}*/}
                    {/*        data-sn={info.sn_new}*/}
                    {/*        data-client={info.client_name}*/}
                    {/*>*/}
                    {/*    <GIcon name={'notifications_paused'}/>*/}
                    {/*    <span className={'button_text'}>{buttons.notifications_paused.title}</span>*/}
                    {/*</button>*/}
                </div>
                <Note info={info} type={"equipment_note"} update_info={setNewChange} isEditing={isEditingNote} setIsEditing={setIsEditingNote}/>
            </div>
            <GenericPopup status={confirmationStatus}/>
            <DetailsTab showTab={tabShown} info={info} setDownloadMessage={setDownloadMessage}/>
        </>
    );
}

export function parseDate(date, isMobile=false) {
    let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (isMobile) {
        options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    }
    if (date) {
        let x = new Date(date)
        date = x.toLocaleDateString("it-it", options)

    }
    return date
}

function get_date_values(date) {
    let x = new Date(date)
    if (isNaN(x.valueOf())) {
        return [null, null, null];
    }
    return [x.getDate(), x.getMonth()+1, x.getFullYear()];
}

function detectExpiration(info, isMobile) {
    const ret = []
    let expClass = 'ok'
    let text = 'Verifica tra '
    let last_ver = "Ultima verifica"
    if (isMobile) {
        text = 'Ver. in '
        last_ver = "Ultima ver."
    }
    let days = info.day_left
    if (days < 0) {
        expClass = 'expired';
        days = days * -1;
        text = 'Scaduta da ';
        if (isMobile) {text = 'Scad. '}
    } else if (days < 30) {
        expClass = 'near';
    }
    let verify_date_parts = get_date_values(info.verify_date)
    ret.push(<span key={'days'+days+info.sn_new} className={'days '+expClass}><span className={"d2_status "+expClass}>{text}</span><span className={'d2'}>{days}</span> gg</span>)
    ret.push(<span key={'label'+days+info.sn_new} className={'label'}>{last_ver}</span>)
    ret.push(<span key={'verify_date'+days+info.sn_new} className={'verify_date'}>
        {verify_date_parts[0].toString().padStart(2, '0')+"/"+verify_date_parts[1].toString().padStart(2, '0')+"/"}
        <span className={'date_year'}>{verify_date_parts[2].toString()}</span>
    </span>)
    return ret
}

function detectDates(info, dateTypes, key) {
    let dateFiltered = []
    let out = []
    for (const dateType of dateTypes) {
        if (dateType['value']) {
            dateFiltered.push(dateType)
        }
    }
    let tot = dateFiltered.length
    let count = 0
    for (const dateType of dateFiltered) {
        let date_parts = get_date_values(dateType['value'])
        let date = parseDate(dateType['value'], key === 'short')
        //Change status if verify is elapsed
        let status = ''
        let d = new Date(dateType['value'])
        if (isNaN(d.valueOf())) {
            console.log("Error parsing date")
            console.log(d)
            console.log(date)
        }
        if (d < new Date()) {
            status = ' ver_elapsed'
        }

        count += 1;
        out.push(
            <span
                key={'l'+dateType[key]+info.sn_new}
                className={'l'+count +'_'+tot+' ver_t'}>
                {dateType[key]}
            </span>
        );
        out.push(
            <span
                key={'v'+dateType[key]+info.sn_new}
                className={'date'+count +'_'+tot+' date' + status}>
                <span className={'date_day'}>{date_parts[0].toString().padStart(2, '0')}</span>{"/"}
                <span className={'date_month'}>{date_parts[1].toString().padStart(2, '0')}</span>{"/"}
                <span className={'date_year'}>{date_parts[2].toString()}</span>
            </span>
        );
    }
    return out
    ;
}