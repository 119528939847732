import './CSS/DetailsTab.css';
import {useState} from "react";
import {silviodb_url} from "./index";
import ReportLine from "./ReportLine";

export function DetailsTab({info, showTab, setDownloadMessage}) {
    //The list of reports to show
    const [reports, setReports] = useState([]);

    if (showTab) {
        let row_company = ""
        let row_contact = ""
        const evaluate = (field, prepend = '', append = '', company = true) => {
            if (info[field]) {
                if (company) {
                    row_company += prepend + info[field] + append;
                } else {
                    row_contact += prepend + info[field] + append;
                }
            }
        }
        evaluate('client_name', '🏭 ',' - ')
        evaluate('city', '🌍 ',' ')
        evaluate('province', '(', ') ')
        evaluate('street', ' - ','')
        evaluate('street_number', ' ','')

        evaluate('contact_person', '👨‍💼 ', ' - ', false)
        evaluate('phone', '📞 ', '', false)


        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort();
            console.error("⏳ Timeout: il server non ha risposto in tempo");
        }, 5000); // Timeout di 5 secondi

        if (reports.length < 1) {
            console.log("Chiedo i report")
            fetch(silviodb_url+'/verbali?sn='+info['sn_new'], { signal: controller.signal })
                .then((response) => {
                    clearTimeout(timeout);
                    if (!response.ok) {
                        throw new Error(`Errore HTTP: ${response.status}`);
                    }
                    return response.json();
                })
                .then((r) => {
                    setReports(r)
                })
                .catch((error) => {
                    console.error("Errore nel fetch:", error.message);
                });
        }


        return <div className={'detail_container'}>
            <div>
                <span className={'subtitle'}>Informazioni cliente</span>
                <span className={'detail_info'}>{row_company}</span>
                <span className={'detail_info'}>{row_contact}</span>
            </div>
            <div>
                <span className={'subtitle'}>Ultimi verbali</span>
                <div className={'report_list'}>
                    <span className={'report_line rl_header'}>
                        <span>File</span>
                        <span>Tipo</span>
                        <span>Data verifica</span>
                        <span>Nome file</span>
                        <span>Verificatore (Affiancamento)</span>
                    </span>
                    {/*<div className={'report_status '+status}>{status}</div>*/}
                    {reports.map((infoElement) => (
                        <ReportLine
                            key={`${infoElement.sn}${infoElement.verify_date}${infoElement.verify_type}${infoElement.report_source}`}
                            infoElement={infoElement}
                            setDownloadMessage={setDownloadMessage}
                            setReports={setReports}
                            reports={reports}
                        />
                    ))}
                </div>
            </div>
        </div>
    }
}
