import PropTypes from "prop-types";

export function InsertTextLine({ label, placeholder, all_info, all_info_key, onChange }) {
    console.log("InsertTextLine: ", all_info);
    return (
        <div className="insert_text_line">
            <span className="short_field field_line label">{label}:</span>
            <textarea
                className="textarea"
                rows="3"
                cols="50"
                placeholder={placeholder}
                value={all_info[all_info_key]}
                onChange={(e) => onChange(all_info_key, e.target.value)}
            >
                {all_info[all_info_key]}
            </textarea>
        </div>
    );
}


InsertTextLine.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    all_info: PropTypes.object.isRequired,
    all_info_key: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};
