import React from 'react';
import {EditableLine} from "./EditableLine";

export function Note({ info, type, update_info, isEditing, setIsEditing }) {

    // La funzione `update_note` aggiorna una nota con un nuovo valore e chiama la funzione `update_info` per modificare l'informazione corrispondente.
    const update_note = (value) => {
        console.log("Updating note with value: ", value);
        update_info({'action': 'modify', 'item': info['sn_new'], 'field': type, 'value': value[type]});
    }

    let note = typeof info[type] === 'string' ? info[type].trim() : info[type];
    if (!note && !isEditing) {
        return null;
    }
    return (
        <div className={'note'}>
            <span className={'note_icon'}>📝</span>
            <EditableLine classFields={["note_text"]} info={info} type={type} update_info={update_note} isEditing={isEditing} setIsEditing={setIsEditing} />
        </div>
    );
}
