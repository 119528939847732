import React, {useCallback, useState} from "react";
import {silviodb_url} from "./index";
import {GIcon} from "./GIcon";
import {parseDate} from "./Line";


const ReportLine = ({ infoElement, setDownloadMessage, setReports, reports}) => {
    const { sn, verify_date, verify_type, report_source, file_name } = infoElement;
    const key = `${sn ?? ""}${verify_date ?? ""}${verify_type ?? ""}${report_source ?? ""}`;
    const img = getIcon(report_source);
    const ver_t = getVerType(verify_type);
    const date = parseDate(verify_date);
    const fn = parseFilename(file_name);
    const verifier = parseVerifier(infoElement);

    const [blur_class, setBlurClass] = useState(" ");
    const handleDelete = (e) => {
        let sn = e.currentTarget.dataset.sn;
        let date = e.currentTarget.dataset.date;
        let verifytype = e.currentTarget.dataset.verifytype;
        let src = e.currentTarget.dataset.source;
        let info = {sn: sn, date: date, type: verifytype, source: src}
        fetch(silviodb_url + '/verbali/cancella', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log("Report cancellato: "+sn+" "+date+" "+verifytype+" "+src)
                    setDownloadMessage({
                        'img': 'ok.svg',
                        'msg': 'Report cancellato',
                        'dismiss': () => {setDownloadMessage({})}
                    });
                    setBlurClass(" ");
                    setReports(reports => reports.filter(report =>
                        !(report.sn === sn) && !(report.verify_date === date) && !(report.verify_type === verifytype) && !(report.report_source === src)
                    ));
                } else {
                    console.log("Impossibile cancellare il report: "+sn+" "+date+" "+verifytype+" "+src)
                    setBlurClass(" deletion_failed")
                }
            });
        setBlurClass(" deletion_in_progress")
    }

    const handleKeyPress = useCallback(
        (e) => {
            if (e.key === "Enter") {
                requestDownload(e, setDownloadMessage);
            }
        },
        [setDownloadMessage]
    );

    return (
        <span key={key} className={"report_line "+blur_class}>
      <img
          className="doc_icon"
          src={img}
          alt=""
          data-sn={sn}
          data-date={verify_date}
          data-verifytype={verify_type}
          data-source={report_source}
          tabIndex={0}
          onClick={(e) => requestDownload(e, setDownloadMessage)}
          onKeyDown={handleKeyPress}
      />
      <span className="ver_type">{ver_t}</span>
      <span className="date">{date}</span>
      <span className="filename">{fn}</span>
      <span className="verifier">{verifier}</span>
      <span className="report_buttons">
        {report_source !== "spreadsheet" && (
            <button
                className="my_button"
                data-sn={sn}
                data-date={verify_date}
                data-verifytype={verify_type}
                data-source={report_source}
                onClick={(e) => handleDelete(e)}
            >
                <img src="delete.svg" alt="delete" />
                <span className="button_text">Cancella</span>
            </button>
        )}
      </span>
    </span>
    );
};

function requestDownload(e, setDownloadMessage) {
    let sn = e.currentTarget.dataset.sn;
    let date = e.currentTarget.dataset.date;
    let verifytype = e.currentTarget.dataset.verifytype;
    let src = e.currentTarget.dataset.source;
    const dismissMessage = () => {setDownloadMessage({})};
    fetch(silviodb_url+'/verbali/download?sn='+sn+'&date='+date+'&type='+verifytype+'&source='+src,{cache: "reload"})
        .then(e => {
            console.log(silviodb_url+'/verbali/download?sn='+sn+'&date='+date+'&type='+verifytype+'&source='+src)
            const r_type = e.headers.get('Content-type');
            console.log("Type:")
            console.log(r_type)
            if (r_type.includes('json')) {
                setDownloadMessage({
                    'img': 'xls.svg',
                    'msg': 'Report non disponibile',
                    'dismiss': dismissMessage
                });
                console.log("Cannot download");
            } else {
                const f_name = e.headers.get('Content-Disposition').split(';', 2)[1].split('"')[1];
                e.blob().then((f) => {
                    downloadReport(f, f_name)
                    setDownloadMessage({
                        'img': 'ok.svg',
                        'msg': 'Report scaricato',
                        'dismiss': dismissMessage
                    });
                });
                setDownloadMessage({
                    'img': 'download.svg',
                    'msg': 'Sto scaricando il report',
                    'dismiss': dismissMessage
                });
            }
        });
}

export function downloadReport(blob, name) {
    console.log(blob)
    const url = window.URL.createObjectURL(new Blob([blob]));
    console.log(url)
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

function getIcon(src) {
    let img = 'xls.svg'
    if (src === 'word_report') {
        img = 'doc.svg'
    } else if (src === 'pdf_report') {
        img = 'pdf.svg'
    }
    return img
}

function getVerType(type) {
    let text = '';
    let translation = [
        {'code': 'ODS', 'icon': 'close', 'color': 'red', 'ita': 'Lista verifiche'},
        {'code': 'PVP', 'icon': 'looks_one', 'color': 'blue', 'ita': 'Prima Periodica'},
        {'code': 'First', 'icon': 'looks_one', 'color': 'blue', 'ita': 'Prima Periodica'},
        {'code': 'VP', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'SC', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'SP', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'Periodic', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'Internal', 'icon': 'center_focus_strong', 'color': 'blue', 'ita': 'Interna'},
        {'code': 'Integrity', 'icon': 'health_and_safety', 'color': 'black', 'ita': 'Integrità'},
        {'code': 'Functioning', 'icon': 'construction', 'color': 'green', 'ita': 'Funzionamento'},
    ]
    if (type) {
        //Evaluate a manual translation based on the given mapping
        for (const t of translation) {
            if (type.trim().toLowerCase() === t['code'].toLowerCase()) {
                return <><GIcon name={t['icon']} color={t['color']}></GIcon>{t['ita']}</>
            }
        }
        text = type;
    } else {
        text = "N/A";
    }
    return text;
}

function parseFilename(filename) {
    if (!filename) {
        filename = '(Solo in lista verifiche)'
    }
    return filename
}

function parseVerifier(infoElement) {
    let verifier = '';
    let v_name = infoElement['verifier']
    let a_name = infoElement['supported']
    if (v_name) {
        if (v_name.toLowerCase().includes('seri')) {
            verifier = "Seri"
        }
    } else {
        verifier = 'N/A'
    }
    if (a_name) {
        verifier += ' ('+a_name+')'
    }
    return verifier;
}

// Usa React.memo per evitare re-render inutili
export default React.memo(ReportLine);