import {silviodb_url} from "./index";
import React from "react";

export function EditableLine({classFields, info, type, update_info, isEditing, setIsEditing}) {
    let class_names = classFields.join(' ');

    function toggleEdit(e) {
        /**
         * In chiusura, salva la nota sul server
         */
        if (isEditing) {
            let noteObject = { sn: info.sn_new, note: info[type] };
            fetch( silviodb_url+'/system/aggiungi_nota', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(noteObject)
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                e.target.classList.remove("closing")
                setIsEditing(!isEditing);
            })
            .catch((error) => {
                e.target.classList.remove("closing")
                e.target.classList.add("note_error")
                console.error('Error:', error);
            });
        }
    }

    function handleChange(e) {
        /**
         * Manage the value of the edit field in order to dynamically update the value
         */
        console.log("Handling change")

        let val = e.target.value
        if (val != null) {
            let clone = {...info}
            console.log(clone)
            if (val.trim() === "") {
                val = null;
            }
            clone[type] = val;
            update_info(clone)
            console.log("Updated line - ", val)

        } else {
            console.log("Ignoring modify")
        }
    }

    function handleBlur(e) {
        /**
         * On focus lost, toggle cell to visualization mode
         */
        setTimeout(() => {
            console.log("Vado!")
            if (e.target.classList.contains("editable")) {
                toggleEdit(e);
            }
        }, 400);
        e.target.classList.add("closing")
    }
    // Non si riesce a scrivere nella nota - il contenuto non cambia
    return <>
        {(isEditing) ? (
            <>
                <input className={class_names+" editable"} type="text" value={info[type] ?? ""} onChange={handleChange} onBlur={handleBlur} autoFocus/>
                <img src={'save.svg'} alt={'Note Icon'} className={'save_note'} />
            </>
        ) : (
            <span className={class_names}>{info[type]}</span>
        )}
    </>
}