import {Component} from "react";
import PropTypes from "prop-types";
import './CSS/InfoBox.css';

function create_info_lines(required_info, my_info) {
    let type = my_info["type"];
    if (type === "SC" || type === "SP") {
        required_info["system_info"] = { title: "🏗️ Apparecchio", value: my_info["device_description"] };
        required_info["company_number"] = { title: "🛠️ Numero di fabbrica", value: my_info.company_number };
        required_info["marca"] = { title: "🏷️ Marca", value: `${my_info.marca} ${my_info.modello && `(${my_info.modello})`}`};
        required_info["portata"] = { title: "🅿️ Portata", value: my_info["portata"] };
    } else if (type === "GVR") {
        required_info["system_info"] = { title: "🛢️ GVR", value: `${my_info["device_description"]} ${my_info["tipo"] && `| ${my_info["tipo"]}`}` };
        required_info["anno"] = { title: "🛠️ Anno", value: my_info.anno };
        required_info["fabbricante"] = { title: "🏷️ Fabbricante", value: my_info.fabbricante};
        required_info["company_number"] = { title: "🛠️ N. fabbrica", value: my_info.nr_fabbrica };
        required_info["marca"] = { title: "🏷️ Modello", value: my_info.model};
        required_info["org"] = { title: "🅿️ Org. notificato", value: my_info.org_notif };
        required_info["insieme"] = { title: "🅿️ Attr. o Insieme", value: ["sì", "si", "yes", "s", "y"].includes(my_info?.insieme_attrezzatura?.toLowerCase()) ? "Insieme a pressione" : "Attrezzatura a pressione" };
        required_info["ce"] = { title: "🅿️ Marcatura CE", value: ["sì", "si", "yes", "s", "y"].includes(my_info?.is_ce?.toLowerCase()) ? "Sì" : "No" };
    }
}

export function InfoBox({ info, type }) {
    const required_info = {};
    const required_info_box = [];
    let titolo = "Informazioni";
    let section_name = "generic";
    if (type === "client") {
        titolo = "Informazioni cliente";
        section_name = type;
        required_info["name"] = { title: "🏭 Nome", value: info.company };
        required_info["city"] = { title: "🏙️ Città", value: `${info.city} ${info.province && `(${info.province})`}` };
        required_info["street"] = { title: "📍 Via", value: `${info.street}${info.street_number && `, ${info.street_number}`}` };
    } else if(type === "equipment") {
        titolo = "Informazioni attrezzatura";
        section_name = type;
        create_info_lines(required_info, info);
    }

    // Crea gli elementi da visualizzare
    for (const [key, value] of Object.entries(required_info)) {
        required_info_box.push(<InfoBoxLine label={value.title} value={value.value} key={key} />);
    }
    return (
        <div className={"info-box " + section_name}>
            <div className="header">
                <h2>{titolo}</h2>
                <button className="edit-button">Modifica</button>
            </div>
            <div className="details">
                {required_info_box}
            </div>
        </div>
    );
}

export class InfoBoxLine extends Component {
    render() {
        const { label, value } = this.props;
        return (
            <p className={"info-line"}><span className={"info-label"}>{label}:</span> <span className={"info single-line"}>{value}</span></p>
        );
    }
}


InfoBox.propTypes = {info: PropTypes.any};