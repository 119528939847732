import {FormDate} from "../FormDate";

export function NextDates({ system_type, info, selected_sn, handleChange }) {
    return (
        <>
            {(system_type === "sc" || system_type === "sp") && (
                <FormDate
                    textFill={"Prossima periodica"}
                    info={info[selected_sn]}
                    valueFill={"next_periodic"}
                    onChange={handleChange}
                />
            )}
            {(system_type === "gvr") && (
                <>
                    <FormDate
                        textFill={"Prossimo funzionamento"}
                        info={info[selected_sn]}
                        valueFill={"next_functioning"}
                        onChange={handleChange}
                    />
                    <FormDate
                        textFill={"Prossima integrità"}
                        info={info[selected_sn]}
                        valueFill={"next_integrity"}
                        onChange={handleChange}
                    />
                    <FormDate
                        textFill={"Prossima interna"}
                        info={info[selected_sn]}
                        valueFill={"next_internal"}
                        onChange={handleChange}
                    />
                </>
            )}
        </>
    );
}
