export function ConductorInfo({ raw_info }) {
    // Solo i GV hanno il campo conduttore
    if (raw_info["device_description"]?.toLowerCase() !== "gv") {
        return null;
    }
    const dates = ["g_license", "exp_license"];
    let relevant_info = {
        conductor: raw_info["conductor"],
        abilitation_n: raw_info["abilitation_n"],
        g_license: raw_info["g_license"],
        exp_license: raw_info["exp_license"]
    };

    // Converti le date in formato leggibile
    for (let date of dates) {
        if (relevant_info[date]) {
            const dateObj = new Date(relevant_info[date]);
            relevant_info[date] = dateObj.toLocaleDateString("it-IT");
        }
    }

    return (
        <div className="technical-data-row">
            <div className="tdt_label conductor">Conduttore</div>
            <div className="tdt_value conductor">{relevant_info.conductor}</div>
            <div className="tdt_label abilitation_n">Abilitazione n°</div>
            <div className="tdt_value abilitation_n">{relevant_info.abilitation_n}</div>
            <div className="tdt_label g_license">Rilasciata il</div>
            <div className="tdt_value g_license">{relevant_info.g_license}</div>
            <div className="tdt_label exp_license">Scadenza il</div>
            <div className="tdt_value exp_license">{relevant_info.exp_license}</div>
        </div>
    );
}
