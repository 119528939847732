import {Component} from "react";
import {InsertTextLine} from "./Form/InsertTextLine";

const verify_sections_spsc = {
    "valutazione_conservazion": {
        "label": "Condizioni conservazione e manutenzione",
        "placeholder": "Inserisci una valutazione sulle condizioni generali di conservazione e manutenzione"
    },
    "device_control": {
        "label": "Esame degli organi principali",
        "placeholder": "Inserisci l'esito dell'esame (es. Regolari)"
    },
    "acc_sec": {
        "label": "Prove di funzionamento e dei dispositivi di sicurezza",
        "placeholder": "Inserisci l'esito dell'esame (es. I dispositivi di sicurezza hanno agito al momento della verifica)"
    },
    "acc_ril": {
        "label": "Configurazione e dati tecnici rilevati",
        "placeholder": "Inserisci i dettagli sulla configurazione (es. informazioni sull'apparecchio e conformità al manuale d'uso)"
    },
    "osservazioni": {
        "label": "Osservazioni",
        "placeholder": "Inserisci eventuali osservazioni (es. accordo stato regione). Non inserire qui la portata o la prossima verifica"
    }
}

export class VerifySection extends Component {
    render() {
        const {info} = this.props;
        const required_fields = []
        if (info.hasOwnProperty("type") && (info.type === "SC" || info.type === "SP")) {
            for (const [key, value] of Object.entries(verify_sections_spsc)) {
                // TODO Gestire il testo tramite uno stato e una funzione per l'aggiornamento di questo
                required_fields.push(<InsertTextLine key={info.type+"_"+key} label={value.label} placeholder={value.placeholder} all_info={info} all_info_key={key} onChange={console.log}/>)
            }
        }

        return <div className={"verify-info"}>
            <h3>Esito della verifica</h3>
            {required_fields}
        </div>
    }
}
